<template>
  <div :class="$vuetify.breakpoint.name === 'lg' ? 'pr-7 pl-7' : 'px-8'">
    <Snackbar ref="snackbar" /> 
    <DialogBookingLicense :dialog="dialog.booking" :code="code" @close="closeDialog()" />

    <!-- DETAIL LIST OERDER -->
    <v-dialog v-model="dialog.detailorder" width="600">
      <v-card>
        <v-card-title>Detail Log</v-card-title>
        <v-row no-gutters class="pa-4">
          <v-col cols="12">
            <div>
              <table>
                <tbody>
                  <tr>
                    <td class="text-no-wrap grey--text text--darken-3 py-1 pr-4">
                      Order Name
                    </td>
                    <span class="py-1" v-if="id.order_id">{{ id.order_id }}</span>
                    <span v-else> - </span>
                  </tr>
                  <tr>
                    <td class="text-no-wrap grey--text text--darken-3 py-1 pr-4">
                      License Code
                    </td>
                    <span class="py-1" v-if="id.code">{{ id.code }}</span>
                    <span v-else> - </span>
                  </tr>
                  <tr>
                    <td class="text-no-wrap grey--text text--darken-3 py-1 pr-4">
                      PMO Code
                    </td>
                    <span class="py-1" v-if="id.with_pmo_code">{{ id.with_pmo_code }}</span>
                    <span v-else> - </span>
                  </tr>
                  <tr>
                    <td class="text-no-wrap grey--text text--darken-3 py-1 pr-4">
                      Merchant
                    </td>
                    <span class="py-1" v-if="id.merchant">{{ id.merchant.name  }}</span>
                    <span v-else> - </span>
                  </tr>
                  <tr>
                    <td class="text-no-wrap grey--text text--darken-3 py-1 pr-4">
                      Program Name
                    </td>
                    <span class="py-1" v-if="id.program">{{ id.program.title }}</span>
                    <span v-else> - </span>
                  </tr>
                  <tr>
                    <td class="text-no-wrap grey--text text--darken-3 py-1 pr-4">
                      User Full Name
                    </td>
                    <span class="py-1" v-if="id.buyer">{{ id.buyer.fullname }}</span>
                    <span v-else> - </span>
                  </tr>
                  <tr>
                    <td class="text-no-wrap grey--text text--darken-3 py-1 pr-4">
                      Email
                    </td>
                    <span class="py-1" v-if="id.buyer">{{ id.buyer.email }}</span>
                    <span v-else> - </span>
                  </tr>
                  <tr>
                    <td class="text-no-wrap grey--text text--darken-3 py-1 pr-4">
                      Active Period Start Date
                    </td>
                    <span class="py-1" v-if="id.start_date">{{ id.start_date | datetime }}</span>
                    <span v-else> - </span>
                  </tr>
                  <tr>
                    <td class="text-no-wrap grey--text text--darken-3 py-1 pr-4">
                      Expiration Date Active Period
                    </td>
                    <span class="py-1" v-if="id.end_date">{{ id.end_date | datetime }}</span>
                    <span v-else> - </span>
                  </tr>
                  <tr>
                    <td class="text-no-wrap grey--text text--darken-3 py-1 pr-4">
                      Duration
                    </td>
                    <span class="py-1" v-if="id.duration_on_days">{{ id.duration_on_days | datetime }}</span>
                    <span v-else> - </span>
                  </tr>
                  <tr>
                    <td class="text-no-wrap grey--text text--darken-3 py-1 pr-4">
                      Created at
                    </td>
                    <span class="py-1" v-if="id.created_at">{{ id.created_at | datetime }}</span>
                    <span v-else> - </span>
                  </tr>
                  <tr>
                    <td class="text-no-wrap grey--text text--darken-3 py-1 pr-4">
                      Date Sold Out
                    </td>
                    <span class="py-1" v-if="id.buyer">{{ id.buyer.sold_at | datetime  }}</span>
                    <span v-else> - </span>
                  </tr>
                  <tr>
                  <td class="text-no-wrap grey--text text--darken-3 py-1 pr-4">
                    Status
                  </td>
                  <td class="py-1">
                    <v-chip v-if="id.is_sold " outlined color="success">Already Sold</v-chip>
                    <v-chip
                      v-else
                      outlined
                      color="warning">
                      Unsold
                    </v-chip>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <div class="text-center mt-4">
              <v-btn color="primary" @click="dialog.detailorder = false">Close</v-btn>
            </div>
          </v-col>
          </v-row>
      </v-card>
      </v-dialog>
    
    <!-- DIALOG FILTER -->
    <v-dialog v-model="dialog.filter" persistent width="650">
      <v-card class="border-radius box-shadow">
        <v-card-title
          class="body-2 text-first d-flex mb-4"
          style="background-color: #f05326">
          <span class="white--text"> Filter </span>
          <v-spacer></v-spacer>
          <v-icon @click="dialog.filter = false" color="white" :disabled="process.run">
            mdi-close-circle
          </v-icon>
        </v-card-title>
        <v-card-text>
          <v-text-field
            outlined
            dense
            flat
            v-model="formFilter.code"
            label="Please Input License Code"
            color="#F05326"
          >
          </v-text-field>
          <v-text-field
            outlined
            dense
            flat
            v-model="formFilter.order_id"
            label="Please Input Order ID"
            color="#F05326"
          >
          </v-text-field>
          <v-autocomplete
            outlined
            dense
            flat
            v-model="formFilter.member_id"
            :items="member.list"
            label="Please Select Member"
            item-value="id"
            item-text="fullname"
            color="#F05326"
            v-if="false"
          >
          </v-autocomplete>
          <v-select
            outlined
            dense
            flat
            v-model="formFilter.is_used"
            :items="[
              { text: 'Used', value: 1 },
              { text: 'Unused', value: 0 },
            ]"
            label="Please Select Usage Status"
            color="#F05326"
          >
          </v-select>
          <v-select
            outlined
            dense
            flat
            v-model="formFilter.is_sold"
            :items="[
              { text: 'Sold Out', value: 1 },
              { text: 'Not Sold Yet', value: 0 },
            ]"
            label="Please Select Selling Status"
            color="#F05326"
          >
          </v-select>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            depressed
            :loading="process.run"
            class="text-capitalize"
            @click="closeFilter('close')"
          >
            Close
          </v-btn>
          <v-btn
            depressed
            color="red"
            class="white--text text-capitalize"
            :loading="process.run"
            @click="closeFilter('reset')"
          >
            Reset
          </v-btn>
          <v-btn
            depressed
            color="#EF6C00"
            class="white--text text-capitalize"
            :loading="process.run"
            @click="fetch()"
          >
            Filter
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- DIALOG CONFIRMATION RESET LICENSE -->
    <v-dialog v-model="dialog.confirm" width="450">
      <v-card>
        <v-card-title>
          Warning !
          <v-spacer></v-spacer>
          <v-icon size="30" @click="dialog.confirm = false" color="red">
            mdi-close-circle-outline
          </v-icon>
        </v-card-title>
        <v-card-text class="subtitle-1 font-weight-bold">
          Are you sure you want to reset this license ?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn elevation="0" class="text-capitalize" @click="dialog.confirm = false">
            Cancel
          </v-btn>
          <v-btn
            color="#F05326"
            elevation="0"
            class="white--text text-capitalize"
            @click="updateLicense(id)"
          >
            Yes, Reset
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog.export" width="500" persistent>
      <v-card class="border-radius box-shadow">
        <v-card-title
          class="body-2 text-first d-flex mb-4"
          style="background-color: #f05326">
          <span class="white--text"> Export Data License </span>
          <v-spacer></v-spacer>
          <v-icon @click="dialog.export = false;exports.limit = 10" color="white" :disabled="process.run">mdi-close-circle</v-icon>
        </v-card-title>
        <v-card-text>
          <ValidationObserver ref="observerExport">
            <v-row>
              <v-col cols="12">
                <ValidationProvider name="Limit Data Export" rules="required|min_value:10|max_value:5000" v-slot="{ errors }">
                  <v-text-field
                    outlined
                    dense
                    flat
                    class="limit_export"
                    v-model="exports.limit"
                    persistent-hint
                    :error-messages="errors"
                    hint="Maximum export 5000 license"
                    label="Limit Export Data"
                    color="#F05326"
                  >
                  </v-text-field>
                </ValidationProvider>
              </v-col>
            </v-row>
          </ValidationObserver>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            depressed
            :loading="process.run"
            class="text-capitalize"
            @click="dialog.export = false; exports.limit = 10"
          >
            Close
          </v-btn>
          <v-btn
            depressed
            color="#EF6C00"
            class="white--text text-capitalize"
            :loading="process.run"
            @click="exportDataExcel"
          >
            Export
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div class="mt-5">
      <div class="title black--text mb-2">List Order License</div>

      <Breadcrumbs :items="breadcrumbs" />

      <v-row justify="space-between" class="mt-2">
        <v-col cols="4">
          <div>
            <v-text-field
              solo
              filled
              dense
              flat
              :hide-details="true"
              placeholder="Search Order License . . ."
              autocomplete="off"
              v-model="searching"
              :readonly="process.run"
              clearable
              color="#F05326"
              @click:clear="searching = ''"
              prepend-inner-icon="mdi-magnify"
            >
            </v-text-field>
          </div>
        </v-col>
        <v-col cols="4" class="text-right">
          <div class="d-flex float-right">
            <v-btn 
              :loading="process.run"
              @click="dialog.export = true"
              color="teal" class="white--text mr-3" depressed>
              <v-icon left>mdi-download</v-icon>
              Export Excel
            </v-btn>
            <!-- <vue-excel-xlsx
              :data="listExcel"
              :columns="columnsExcel"
              :file-name="excelName"
              :file-type="'xlsx'"
              :sheet-name="'List Order Lisensi'"
            >
            </vue-excel-xlsx> -->
            <v-btn 
              depressed
              @click="filter"
              :loading="process.run"
              elevation="0"
              color="#F5F5F5"
              class="black--text text-capitalize"
            >
              Filter by
              <v-icon right>mdi-filter-variant</v-icon>
            </v-btn>
          </div>
        </v-col>
      </v-row>

      <div class="mt-3 mb-8">
        <v-skeleton-loader
          class="ma-auto"
          :loading="$store.state.process.run"
          :tile="false"
          transition="scale-transition"
          type="table-tbody"
        >
          <v-data-table
            class="table-list"
            hide-default-footer
            fixed-header
            :loading="$store.state.process.run || process.run"
            :headers="headers"
            :items="items.list"
            :items-per-page.sync="items.limit"
            :page.sync="items.page"
            :server-items-length="items.total"
            item-key="id"
            :footer-props="{
              itemsPerPageText: 'Data per halaman',
              'items-per-page-options': [5, 10, 20, 50, 100, 200, 350, 500, 1000, 5000],
            }"
          >
            <template slot="footer" slot-scope="{ props }">
              <Pagination
                @reload="fetch(page)"
                @changeLimit="
                  limit = $event;
                  fetch();
                "
                @changePage="
                  page = $event;
                  fetch($event);
                "
                :model="props"
              />
            </template>

            <template slot="item" slot-scope="props">
              <tr class="mx-3 px-3 box-shadow">
                <td class="text-start white">
                  {{ props.item.order_id }}
                </td>
                <td class="text-center white">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <div 
                        v-bind="attrs"
                        v-on="on"
                        style="text-decoration: underline; color: #F05326; text-underline-offset: 4px;"
                        @click="onCopyToClipboard(props.item.code)" class="cursor-pointer">
                        {{ props.item.code }}
                        </div>
                      </template>
                      <span>Klik to copy</span>
                    </v-tooltip>
                </td>
                <td class="text-center white">
                  {{ props.item.merchant.name }}
                </td>
                <td class="text-start white">
                  {{ props.item.program.title.substring(0, 40) }} . . .
                </td>
                <td class="text-start white">
                  {{
                    (props.item.user.full_name || props.item.buyer.fullname) | emptyData
                  }}
                  <br />
                  <span class="deep-orange--text text--darken-1">{{
                    (props.item.user.email || props.item.buyer.email) | emptyData
                  }}</span>
                </td>
                <td class="text-center white">
                  <v-row align="center" justify="center">
                    <v-tooltip bottom v-if="props.item.is_sold">
                      <template v-slot:activator="{ on, attrs }">
                        <v-chip
                          class="mb-2"
                          v-on="on"
                          v-bind="attrs"
                          outlined
                          color="green"
                          small
                          v-if="props.item.is_sold"
                        >
                          Already Sold : {{ props.item.buyer.sold_at | datetime }}
                        </v-chip>
                      </template>
                      <div>Buyer Name : {{ props.item.buyer.fullname }}</div>
                      <div>Buer Email : {{ props.item.buyer.email }}</div>
                    </v-tooltip>
                    <v-chip v-else class="mb-2" outlined color="warning" small>
                      Belum Terjual
                    </v-chip>
                  </v-row>
                  <v-row align="center" justify="center">
                    <v-chip
                      class="mb-2"
                      outlined
                      color="blue"
                      small
                      v-if="props.item.is_used"
                    >
                    Already Used : {{ props.item.user.used_at | datetime }}
                    </v-chip>
                  </v-row>
                  <v-row align="center" justify="center">
                    <v-chip v-if="props.item.with_pmo_code" outlined color="orange" small>Whit PMO Code</v-chip>
                  </v-row>
                </td>
                <td class="text-center white">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        color="blue"
                        v-bind="attrs"
                        v-on="on"
                        @click="bookingLisence(props.item)"
                      >
                        <v-icon>mdi-account-plus</v-icon>
                      </v-btn>
                    </template>
                    <span>Booking License</span>
                  </v-tooltip>
                  <div class="text-no-wrap">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          color="error"
                          v-bind="attrs"
                          v-on="on"
                          @click="resetLisence(props.item.id)"
                        >
                          <v-icon>mdi-lock-reset</v-icon>
                        </v-btn>
                      </template>
                      <span>Reset License</span>
                    </v-tooltip>
                  </div>
                  <div class="text-no-wrap">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          color="blue-grey"
                          v-bind="attrs"
                          v-on="on"
                          @click="showDetailOrder(props.item)"
                        >
                          <v-icon>mdi-file-document-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>Detail List</span>
                    </v-tooltip>
                  </div>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-skeleton-loader>
      </div>
    </div>
  </div>
</template>

<script>
import { get, post, put, destroy } from "@/service/Axios";
import GridTable from "@/components/GridTable";
import Pagination from "@/components/Pagination.vue";
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import Snackbar from "@/components/Snackbar.vue";
import DialogBookingLicense from "./DialogBookingLicense.vue";
import moment from "moment";

export default {
  components: { GridTable, Pagination, Breadcrumbs, Snackbar, DialogBookingLicense },
  data() {
    return {
      title: "List Users",
      limit: 10,
      page: 1,
      selected: {
        pagination: 0,
      },
      breadcrumbs: [
        {
          text: "List Order",
          disabled: false,
          href: "/order",
        },
        {
          text: "List Order License",
          disabled: true,
          href: "/order",
        },
      ],
      headers: [
        { text: "Order Name", align: "start", value: "order_id", sortable: false },
        { text: "License Code", align: "center", value: "code", sortable: false },
        { text: "Merchant", align: "center", value: "merchant.name", sortable: false },
        { text: "Program Name", align: "start", value: "program.title", sortable: false },
        {
          text: "User Full Name",
          align: "start",
          value: "user.fullname",
          sortable: false,
        },
        { text: "Status", align: "center", value: "status", sortable: false },
        { text: "Actions", align: "center", value: "actions", sortable: false },
      ],
      items: {},
      member: {},
      exports: {
        limit: 10,
      },
      excelName: "",
      listExcel: [],
      searching: "",
      process: {
        run: false,
      },
      id: "",
      code: "",
      formFilter: {
        member_id: "",
        license: "",
        code: "",
        order_id: "",
        is_used: "",
        is_sold: "",
      },
      detailorder: {
        id: "",
      },
      dialog: {
        filter: false,
        export: false,
        confirm: false,
        booking: false,
        detailorder: false,
      },
    };
  },
  watch: {
    "selected.pagination": function (val) {
      this.fetch(val + 1);
    },
    searching: _.debounce(function (newVal) {
      this.searching = newVal;
      this.fetch();
    }, 500),
  },
  created() {
    this.fetch();
    this.excelName = "List Order Lisensi " + this.$route.params.id;
    // this.fetchMember()
  },
  computed: {
    columnsExcel() {
      return [
        {
          label: "Order Name",
          field: "order_id",
        },
        {
          label: "License Code",
          field: "code",
        },
        {
          label: "Merchant",
          field: "merchant.name",
        },
        {
          label: "Program Name",
          field: "program.title",
        },
        {
          label: "User Full Name",
          field: "user.fullname",
        },
        {
          label: "Date Sold Out",
          field: "buyer.sold_at",
          dataFormat: (value) => {
            return value != null ? moment(value).format("DD/MM/YYYY HH:mm:ss") : "-";
          },
        },
        {
          label: "Date of Use",
          field: "user.used_at",
          dataFormat: (value) => {
            return value != null ? moment(value).format("DD/MM/YYYY HH:mm:ss") : "-";
          },
        },
      ];
    },
  },
  mounted() {},
  methods: {
    onCopyToClipboard(item) {
      navigator.clipboard.writeText(item);
      this.$refs.snackbar.open("#4CAF50", `Berhasil Copy Kode`);
    },
    props(item){

    },
    changePage(event) {
      if (event === undefined) {
        this.selected.pagination = 0;
      }
    },
    filter() {
      this.dialog.filter = true;
      // this.$refs.snackbar.open("#4CAF50", `Mohon Maaf Fitur Belum Tersedia`);
    },
    closeFilter(state) {
      this.dialog.filter = false;
      if (state != "close") {
        this.formFilter = {
          member_id: "",
          license: "",
          code: "",
          order_id: "",
          is_used: "",
          is_sold: "",
        };
        this.fetch();
      }
    },
    async fetch(paging) {
      let _self = this;
      _self.process.run = true;

      await get(`license/v1/superuser/list_license`, {
        params: {
          limit: this.limit,
          page: paging,
          q: this.searching,
          order_id: this.$route.params.id,
          user_id: this.formFilter.member_id,
          code: this.formFilter.code,
          is_used: this.formFilter.is_used,
          is_sold: this.formFilter.is_sold,
        },
      }).then((response) => {
        let res = response.data;
        this.items = res.data;
        this.process.run = false;
        this.$store.state.process.run = false;
        this.totalData = res.data.total;
        this.fetchDataExcel();
        this.closeFilter("close");
      });
    },
    async exportDataExcel() {
      const isValid = await this.$refs.observerExport.validate();
      if (isValid) {
        this.process.run = true;
        await get(`license/v1/superuser/export-license`, {
          params: {
            order_id: this.$route.params.id,
            limit: this.exports.limit,
          }
        }).then((response) => {
          let res = response.data;

          if(res.data.public_url) {
            const link = document.createElement("a");
            link.href = res.data.public_url;
            link.setAttribute("download", `${res.data.title}`);
            document.body.appendChild(link);
            link.click();
            this.process.run = false;
            this.dialog.export = false;
            this.$refs.snackbar.open("#4CAF50", res.data.message);
          }else {
            this.process.run = false;
            this.dialog.export = false;
            this.$refs.snackbar.open("#D32F2F", `Failed to export data`);
          }
        }).catch((err) => {
          this.loadingExport = false;
          this.snackbar = {
            state: true,
            text: err.response.data.message,
          }
        });
      }
    },
    async fetchDataExcel() {
      let _self = this;
      _self.process.run = true;
      await get(`license/v1/superuser/list_license`,{
        params:{
          limit: this.limit,
          q: this.searching,
          order_id: this.$route.params.id,
          user_id: this.formFilter.member_id,
          code: this.formFilter.code,
          is_used: this.formFilter.is_used,
          is_sold: this.formFilter.is_sold,
        },
      }).then((response) => {
        let res = response.data;
        this.listExcel = res.data.list;
        this.process.run = false;
        this.$store.state.process.run = false;
        this.totalData = res.data.total;
        this.closeFilter("close");
      });
    },
    async fetchMember(paging) {
      let _self = this;
      _self.process.run = true;

      await get(`superuser/v1/member`, {
        params: {
          limit: this.limit,
          page: paging,
          sort: "created_at",
          dir: "asc",
        },
      }).then((response) => {
        let res = response.data;
        this.member = res.data;
        this.process.run = false;
        this.$store.state.process.run = false;
        this.totalData = res.data.total_data;
      });
    },
    resetLisence(id) {
      this.dialog.confirm = true;
      this.id = id;
    },
    async updateLicense(id) {
      this.process.run = true;
      await put(`license/v1/superuser/update/${id}`, {
        data: {
          is_used: false,
        },
      })
        .then((response) => {
          let res = response.data;
          if (res.status == 200) {
            this.$refs.snackbar.open("#4CAF50", `Berhasil Reset Voucher`);
            this.fetch();
          } else {
            this.$refs.snackbar.open("#D32F2F", `Gagal Reset Voucher`);
          }
          this.dialog.confirm = false;
          this.process.run = false;
        })
        .catch(() => {
          this.process.run = false;
          this.dialog.confirm = false;
          // this.$refs.snackbar.open("#D32F2F", `Gagal Reset Voucher`);
        });
    },
    bookingLisence(item) {
      this.code = item.code;
      this.dialog.booking = true;
    },
    closeDialog() {
      this.dialog.booking = false;
      this.fetch();
      // this.fetchMember()
    },
    showDetailOrder(item) {
      console.log(item);
      this.id = item;
      this.dialog.detailorder = true;
    },
  },
};
</script>

<style>
.limit_export > .v-input__control > .v-text-field__details > .v-messages > .v-messages__wrapper > .v-messages__message {
  color: red;
}
</style>